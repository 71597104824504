import { ScoreString } from "../../../common/__generated__/client-types";

export const toTitleCase = (str: string): string => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const isEmailValid = (email: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const displayScores: { [key in ScoreString]: string } = {
    [ScoreString.High]: "High",
    [ScoreString.Low]: "Low",
    [ScoreString.Medium]: "Medium",
    [ScoreString.VeryHigh]: "Very High",
    [ScoreString.VeryLow]: "Very Low",
};

export const deslugify = (str: string) => {
    const result = str.replace(/_/g, " ");
    return capitalise(result);
};

export const conditionalLowercase = (str: string): string => {
    const alwaysUpper = ["encore", "tnfd", "csrd", "leap", "esrs"];

    return str
        .split(" ")
        .map((word) => {
            if (alwaysUpper.includes(word.toLowerCase())) {
                return word.toUpperCase();
            }
            return word.toLowerCase();
        })
        .join(" ");
};

export const replaceDotsWithSpaces = (str: string): string => {
    return str.replace(/\./g, " ");
};

export const booleanStringMap: { [key: string]: string } = {
    True: "Yes",
    False: "No",
};

export const unitMap: { [key: string]: string } = {
    "metres squared": " m²",
    "m<sup>3</sup>": " m³",
    percent: "%",
    "percentage points": "% points",
    "kilograms per year": " kg/year",
    "kilograms per millimetre per hectare": " kg/mm/ha",
    "kilograms per hectare per year": " kg/ha/year",
    "metres cubed per year": " m³/year",
    "milligrams of carbon/metre squared/day": " mg C/m²/day",
};

export const capitalise = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
